import { useHistory } from 'react-router-dom';

import '../../styles/common/Footer.scss';

const Footer = () => {
  const history = useHistory();

  return (
    // Hide the footer if embed
    (!history.location.pathname.includes('/e/') || history.location.pathname.includes('/e/viewer/')) &&
    <div className={`Footer${history.location.pathname.includes('/e/viewer/') ? ' viewer' : ''}`}>
      <div className="content">
        <div className="copyright">
          {`© ${new Date().getFullYear()} Gravity - All rights reserved`}
        </div>
        <div className="version">
          Ver. 3.3.0
        </div>
      </div>
    </div>
  );
}

export default Footer;
