import { useContext, useState, /*useRef,*/ useEffect } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from "react-router-dom";

import Participant from './Participant';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/common/ProfileControl.scss';

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const SettingsIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/settings.svg`;
const SettingsSelectedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/settings-selected.svg`;
const ProfileIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/profile.svg`;
const ProfileSelectedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/profile-selected.svg`;
const LogoutIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/logout.svg`;
const LogoutSelectedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/logout-selected.svg`;
const CloseIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/close.svg`;

const TutorialsIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/tutorials.svg`;
const TutorialsSelectedIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/tutorials-selected.svg`;

const ProfileControl = () => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const [menuOpen, setMenuOpen] = useState(false);
  const [menuHover, setMenuHover] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  const [activeItem, setActiveItem] = useState('');

  const [avatar, setAvatar] = useState((constants.utils.isObject(store.profile) && store.profile.image !== undefined && store.profile.image !== '') ? store.profile.image : DefaultAvatar);
  //const cacheAvatar = useRef(null);

  const endSession = () => {
    const languageArray = window.navigator.language.split('');
    const detectedLanguage = `${languageArray[0]}${languageArray[1]}`;
    const languages = ['en', 'zh', 'nl'];

    const storeCopy = {
      language: ((languageArray.length > 1 ? (languages.includes(detectedLanguage) ? detectedLanguage : 'en') : 'en')),
      profile: {},
      subscription: {
        active: false,
        type: null,
        tokens: null
      },
      communities: {
        data: [],
        fetched: false
      },
      groups: {
        data: [],
        fetched: false
      },
      spaces: {
        data: [],
        fetched: false
      },
      assets: {
        data: {},
        fetched: false
      },
      status: {
        community: {},
        group: {},
        space: {},
        asset: {},
        members: [],
        message: {
          type: null,
          text: null
        },
        modal: {
          active: false,
          action: null,
          type: null,
          store: null,
          thread: null,
          data: {
            title: null,
            description: null,
            inputTitle: null,
            placeholder: null,
            privacySetting: false
          }
        },
        recorder: {
          active: false,
          responding: {
            active: false,
            recording: false,
            started: false
          }
        },
        settings: {
          transcript: false,
          translate: false,
          public: false,
        },
        menu: true,
        tutorials: {
          active: false,
          backFrom: false,
          content: null
        }
      },
      session: {},
      reload: {
        header: null,
        communities: null
      },
      marketing: {
        school: store.marketing.school
      },
      viewer: {
        active: false
      }
    };
    dispatch({
      type: 'store',
      data: storeCopy,
    });

    constants.services.endSession();
    history.push("/login/");
  };

  const openMenu = () => {
    if (menuOpen) {
      setMenuActive(false);
    } else {
      setTimeout(() => {
        setMenuActive(true);
      }, 100);
    }
    setMenuOpen(!menuOpen);
  }

  const editProfile = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'editProfile',
        data: {
          title: strings.default[store.language].Header.EditProfile,
          description: strings.default[store.language].Modal.ChangeDescription,
          inputTitle: strings.default[store.language].LoginRegister.FullName,
          value: store.profile.name,
          placeholder: strings.default[store.language].AssetsView.ResponseDescriptionPlaceholder,
          privacySetting: false
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const setLanguage = (language) => {
    dispatch({
      type: 'language',
      data: language
    });
  }

  const openTutorials = () => {
    const storeCopy = {
      ...store,
      spaces: {
        ...store.spaces,
        fetched: false
      },
      status: {
        ...store.status,
        tutorials: {
          active: true,
          backFrom: true,
          content: null
        }
      }
    }
    dispatch({
      type: 'store',
      data: storeCopy
    });
    history.push('/t/');
  }

  useEffect(() => {
    if (store.reload.header !== null) {
      setAvatar(store.profile.image.length ? `${store.profile.image}?random=${parseInt(Math.random() * 1000000000)}` : '');

      const reloadCopy = {
        ...store.reload,
        header: null
      }
      dispatch({
        type: 'reload',
        data: reloadCopy
      });
    }
    // TO-DO: Track down root cause of this profile image bug
    /*else {
      if (store.profile.image !== undefined && cacheAvatar.current === null) {
        cacheAvatar.current = store.profile.image;
      }
      if (avatar !== DefaultAvatar && cacheAvatar.current !== null) {
        setAvatar(cacheAvatar.current);

        const profileCopy = {
          ...store.profile,
          image: cacheAvatar.current
        }
        dispatch({
          type: 'profile',
          data: profileCopy
        });
      } else {
        setAvatar((constants.utils.isObject(store.profile) && store.profile.image !== undefined && store.profile.image !== '') ? store.profile.image : DefaultAvatar);
      }
    }*/
  }, [store.reload.header, store.profile, dispatch, store.reload, avatar]);

  return (
    <div className="ProfileControl">
      <div className="options">
        {(store.profile && constants.utils.isObject(store.profile)) &&
          <div className="optionsWrapper">
            <div className="setting menuAvatar">
              <button
                className={`settingsIcon${menuOpen ? ' open' : ''}`}
                onMouseEnter={() => setMenuHover(true)}
                onMouseLeave={() => setMenuHover(false)}
                onClick={() => openMenu()}>
                {avatar === DefaultAvatar || avatar === '' ?
                  <Participant
                    i={0}
                    participant={{
                      name: store.profile.name
                    }}
                    display /> :
                  <div
                    className="avatar"
                    style={{ backgroundImage: `url(${constants.services.s3ToCloudfrontMedia(avatar)})` }} />
                }
                {(menuOpen || menuHover) &&
                  <>
                    <div className="avatarOverlay" />
                    <div
                      className="settingsImage"
                      style={{ backgroundImage: `url(${menuOpen ? (menuActive ? CloseIcon : SettingsSelectedIcon) : SettingsIcon})` }} />
                  </>
                }

              </button>
            </div>
            {menuOpen &&
              <div className="contextMenu">
                <div className="languages">
                  <div className="languageWrapper">
                    <button
                      className={`language en${store.language === "en" ? ' active' : ''}`}
                      disabled={store.language === "en"}
                      onClick={() => setLanguage('en')}>
                      EN
                    </button>
                  </div>
                  <div className="languageWrapper">
                    <button
                      className={`language zh${store.language === "zh" ? ' active' : ''}`}
                      disabled={store.language === "zh"}
                      onClick={() => setLanguage('zh')}>
                      中文
                    </button>
                  </div>
                  <div className="languageWrapper">
                    <button
                      className={`language nl${store.language === "nl" ? ' active' : ''}`}
                      disabled={store.language === "nl"}
                      onClick={() => setLanguage('nl')}>
                      NL
                    </button>
                  </div>
                </div>
                <button
                  className="control"
                  onMouseEnter={() => setActiveItem('menuProfile')}
                  onMouseLeave={() => setActiveItem('')}
                  onClick={() => editProfile()}>
                  <div className="menuIcon">
                    <img
                      src={activeItem === 'menuProfile' ? ProfileSelectedIcon : ProfileIcon}
                      className="menuImage"
                      alt={strings.default[store.language].Header.EditProfile} />
                  </div>
                  <div className={`menuText${store.language === "zh" ? ' zh' : ''}`}>
                    {strings.default[store.language].Header.EditProfile}
                  </div>
                </button>
                <button
                  className="control"
                  onMouseEnter={() => setActiveItem('menuTutorials')}
                  onMouseLeave={() => setActiveItem('')}
                  onClick={() => openTutorials()}>
                  <div className="menuIcon">
                    <img
                      src={activeItem === 'menuTutorials' ? TutorialsSelectedIcon : TutorialsIcon}
                      className="menuImage"
                      alt={strings.default[store.language].Dashboard.Tutorials} />
                  </div>
                  <div className={`menuText${store.language === "zh" ? ' zh' : ''}`}>
                    {strings.default[store.language].Dashboard.Tutorials}
                  </div>
                </button>
                <button
                  className="control"
                  onMouseEnter={() => setActiveItem('menuLogout')}
                  onMouseLeave={() => setActiveItem('')}
                  onClick={() => endSession()}>
                  <div className="menuIcon">
                    <img
                      src={activeItem === 'menuLogout' ? LogoutSelectedIcon : LogoutIcon}
                      className="menuImage"
                      alt={strings.default[store.language].Header.Logout} />
                  </div>
                  <div className={`menuText${store.language === "zh" ? ' zh' : ''}`}>
                    {strings.default[store.language].Header.Logout}
                  </div>
                </button>
              </div>
            }
          </div>
        }
      </div>
    </div>
  );
}

export default ProfileControl;