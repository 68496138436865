import { useState, useContext, useRef, useEffect } from 'react';
import { Context } from '../../DataStore';

import Loading from '../common/Loading';
import CreatorStudio from '../dashboard/CreatorStudio';
import Participant from '../common/Participant';
import Member from '../common/Member';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/composite/SpacesView.scss';
import '../../styles/exports/animation.scss';

const DefaultAvatar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/default-robot.svg`;
const DeleteIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/delete.svg`;
const CrownIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/crown.svg`;
const ChatBubbleBlack = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/chat-bubble-black.svg`;
const Check = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/check.svg`;
const CheckMarked = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/check-marked.svg`;
const noPromptsIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/no-prompts.svg`;
const VoiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/voice.svg`;
const DocumentIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/document.svg`;
const YoutubeIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/youtube.svg`;
const MembersViewIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/members-view.svg`;
const MembersCloseIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/members-close.svg`;
const GravityStar = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gravity-star.svg`;

const NarchivesIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/naarchives-logo.png`;
const FigmaIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/figma.svg`;
const GdriveIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gdrive.svg`;
const CanvaIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/canva.svg`;
const ImageIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/image-icon.svg`;

const CalendarIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/calendar.svg`;

const DefaultBackground = `${process.env.REACT_APP_CF_MKT_ENDPOINT}jpg/gravity-waves.jpg`;

const SpacesView = (props) => {
  const { store, dispatch } = useContext(Context);
  const [loadingMembers, setLoadingMembers] = useState(false);
  const [viewingMembers, setViewingMembers] = useState(false);

  const [fileInputKey, setFileInputKey] = useState(Date.now());
  const [fileName, setFileName] = useState(null);
  const [extension, setExtension] = useState(null);
  const [upload, setUpload] = useState(null);
  const [imageChanged, setImageChanged] = useState(false);

  const [backgroundImage, setBackgroundImage] = useState('');
  const [cachedBackgroundImage, setCachedBackgroundImage] = useState('');

  const [uploading, setUploading] = useState(false);
  const fileUpload = useRef(null);
  const cachedGid = useRef(null);

  const fileSelected = (event) => {
    const fileData = event.target.files[0];
    const fileUrl = URL.createObjectURL(fileData);
    setBackgroundImage(fileUrl);
    setFileName(`${store.status.group.gid}_${Date.now()}_${fileData.name}`);

    const fileNameArr = fileData.name.split('.');
    const fileType = fileNameArr[fileNameArr.length - 1];
    setExtension(`image/${fileType}`);
    setImageChanged(true);
    setUpload(fileData);

    setFileInputKey(Date.now());
  }

  const commitToDatabase = async (location) => {
    const data = {
      uuid: store.profile.uuid,
      sessionId: store.session.sessionId,
      gid: store.status.group.gid,
      image: location
    }

    try {
      const url = `${constants.services.url.api}/group/modify/background/`;
      const response = await fetch(url, constants.services.config(data));
      const responseData = await response.json();

      setUploading(false);

      if (response.ok) {
        if (responseData.status === 'Success') {
          let groupsDataCopy = [
            ...store.groups.data
          ]
          const groupIndex = groupsDataCopy.map((groupData) => groupData.gid).indexOf(store.status.group.gid);
          if (groupIndex > -1) {
            groupsDataCopy[groupIndex].background = location;

            setBackgroundImage(location.length ? location : DefaultBackground);
            setCachedBackgroundImage(location.length ? location : DefaultBackground);
            setExtension(null);
            setUpload(null);
            setImageChanged(false);

            const storeCopy = {
              ...store,
              groups: {
                ...store.groups,
                data: groupsDataCopy
              },
              status: {
                ...store.status,
                modal: {
                  active: false,
                  action: null,
                  type: null,
                  store: null,
                  thread: null,
                  data: {
                    title: null,
                    description: null,
                    inputTitle: null,
                    placeholder: null,
                    privacySetting: false
                  }
                }
              }
            }

            dispatch({
              type: 'store',
              data: storeCopy
            });
          }
        }
      }
    } catch (err) {
    }
  }

  const uploadBackground = async () => {
    setUploading(true);

    try {
      let urlData = {
        uuid: store.profile.uuid,
        sessionId: store.session.sessionId,
        fileName: fileName,
        fileType: extension
      }

      const url = `${constants.services.url.api}/asset/generate/presigned-url/`;
      const response = await fetch(url, constants.services.config(urlData));
      const responseData = await response.json();

      if (response.ok) {
        if (responseData.status === 'Success') {
          // Direct upload to s3 with presigned URL
          const uploadResponse = await fetch(responseData.uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': extension
            },
            body: upload
          });

          if (uploadResponse.ok) {
            const s3Location = (uploadResponse.url).split('?')[0];
            commitToDatabase(s3Location);
          }
        }
      } else {
        setUploading(false);
      }
    } catch (err) {
      setUploading(false);
    }
  }

  const removeBackground = (event) => {
    if (store.status.modal.active) {
      commitToDatabase('');
    } else {
      event.persist();
      const statusCopy = {
        ...store.status,
        modal: {
          active: true,
          action: 'delete',
          type: 'confirm',
          store: '',
          thread: null,
          event: event,
          data: {
            title: strings.default[store.language].Menu.ConfirmDeleteBackground,
            description: strings.default[store.language].Menu.ConfirmDeleteBackgroundDescription,
            privacySetting: true
          }
        }
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }

  const cancelImageChange = () => {
    setExtension(null);
    setUpload(null);
    setImageChanged(false);
    setBackgroundImage(cachedBackgroundImage);
  }

  const fetchMembers = async () => {
    if (viewingMembers) {
      setViewingMembers(false);
    } else {
      if (store.status.members.length && store.status.members[0].cid === store.status.community.cid) {
        setViewingMembers(true);
      } else {
        setLoadingMembers(true);

        try {
          let data = {
            cid: store.status.community.cid,
            uuid: store.profile.uuid,
            sessionId: store.session.sessionId
          }
          const url = `${constants.services.url.api}/community/fetch/members/`;
          const response = await fetch(url, constants.services.config(data));
          const responseData = await response.json();

          if (responseData.status === 'Success') {
            setLoadingMembers(false);
            setViewingMembers(true);

            const statusCopy = {
              ...store.status,
              members: responseData.data
            }
            dispatch({
              type: 'status',
              data: statusCopy
            });
          }
        } catch (_) {
          setLoadingMembers(false);
          setViewingMembers(false);
        }
      }
    }
  }

  useEffect(() => {
    if (cachedGid.current !== store.status.group.gid) {
      const initialBackground = (store.status.group.background !== undefined && store.status.group.background !== '' ? store.status.group.background : DefaultBackground);
      setBackgroundImage(initialBackground);
      setCachedBackgroundImage(initialBackground);

      cachedGid.current = store.status.group.gid;
    }


    if (store.status.members.length && (store.status.members[0].cid !== store.status.community.cid)) {
      setViewingMembers(false);
      const statusCopy = {
        ...store.status,
        members: []
      }
      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }, [store.status.community.cid, store.status.members, viewingMembers, dispatch, store.status]);

  return (
    <div className="SpacesView">
      <div className={`content${props.loadingDeeplink ? ' blurred' : ''}`}>

        <CreatorStudio mini />

        <div className="layoutBuffer"></div>

        <div className="background">
          <div
            className="backgroundImage"
            style={{ backgroundImage: `url(${constants.services.s3ToCloudfrontMedia(backgroundImage)})` }} />
          <div className="backgroundOverlay" />
          <div className="backgroundText">
            {store.status.group.title}
          </div>
          {uploading &&
            <div className="backgroundLoading">
              <Loading active={true} />
            </div>
          }
          {store.status.group.creator === store.profile.uuid &&
            <>
              <div className="backgroundManager">
                <div className="backgroundEdit">
                  <button
                    className="backgroundButton"
                    onClick={() => fileUpload.current.click()}>
                    <img
                      className="backgroundIcon"
                      src={ImageIcon}
                      alt="Change background Icon" />
                  </button>
                </div>
              </div>
              {imageChanged ?
                <div className="actionButtonsWrapper">
                  <div className="actionButtons">
                    <button
                      className="actionButton saveButton"
                      disabled={uploading}
                      onClick={() => uploadBackground()}>
                      {strings.default[store.language].Modal.Submit}
                    </button>
                  </div>
                  <div className="actionButtons">
                    <button
                      className="actionButton cancelButton"
                      disabled={uploading}
                      onClick={() => cancelImageChange()}>
                      {strings.default[store.language].Modal.Cancel}
                    </button>
                  </div>
                </div> :
                cachedBackgroundImage !== DefaultBackground &&
                <div className="actionButtonsWrapper">
                  <div className="actionButtons">
                    <button
                      className="actionButton removeButton"
                      disabled={uploading}
                      onClick={(event) => removeBackground(event)}>
                      {strings.default[store.language].Modal.Remove}
                    </button>
                  </div>
                </div>
              }
            </>
          }
          <input
            className="input"
            key={fileInputKey}
            ref={fileUpload}
            type="file"
            accept="image/png, image/jpeg, image/apng, image/avif, image/gif, image/svg+xml, image/webp"
            onChange={(event) => fileSelected(event)} />
        </div>

        <div className="membersButtonWrapper">
          <div className="membersButton">
            <button
              className="fetchMembers"
              disabled={loadingMembers}
              onClick={() => fetchMembers()}>
              {loadingMembers ?
                <Loading active="true" /> :
                <img
                  className={`membersIcon${viewingMembers ? ' close' : ''}`}
                  src={viewingMembers ? MembersCloseIcon : MembersViewIcon}
                  alt="Members" />
              }
              <div className="buttonText">
                {`${strings.default[store.language][viewingMembers ? 'Modal' : 'AssetsView'][viewingMembers ? 'Hide' : 'View']}${store.language !== 'zh' ? ' ' : ''}${strings.default[store.language].SpacesView.Members}`}
              </div>
            </button>
          </div>
          {(store.status.members.length > 0 && viewingMembers) &&
            <div className="membersView">
              <div className="membersCountWrapper">
                <div className="membersCount">
                  <img
                    src={GravityStar}
                    className="countShape"
                    alt="Gravity Star" />
                  <div className="count">
                    {store.status.members.length}
                  </div>
                  <div className="membersCommunity">
                    {store.status.community.title}
                  </div>
                </div>
              </div>
              {store.status.members.map((member, i) => (
                <Member
                  key={`Member${i}`}
                  member={member} />
              ))}
            </div>
          }
        </div>

        {store.spaces.data.length && (store.status.group.cid === store.status.community.cid) ?
          <div className={`data${viewingMembers ? ' membersActive' : ''}`}>
            {store.spaces.data.map((space, i) => (
              <div
                className="spaceWrapper"
                key={`SpaceWrapper${i}`}>
                <div className={`space${space.scheduled !== undefined && space.scheduled ? ' scheduled' : ''}`}>
                  <button
                    className="spaceData"
                    onClick={() => props.loadSpace(space)}>
                    {(space.scheduled !== undefined && space.scheduled) &&
                      <div className="scheduled">
                        <div className="scheduledText">
                          <img
                            className="calendarIcon"
                            src={CalendarIcon}
                            alt="Calendar" />
                          <span className="scheduledTextNode">
                            {`${strings.default[store.language].SpacesView.ScheduledFor} ${constants.utils.parseDate(space.creation)}`}
                          </span>
                        </div>
                      </div>
                    }
                    <div className="preview">
                      {space.type === 'voice' ?
                        <img
                          className="previewAuthor voice"
                          src={VoiceIcon}
                          alt="Voice" /> :
                        <div
                          className="previewVideo"
                          style={{ backgroundImage: `url("${constants.services.awsLocation(true)}/${space.aid}.gif")` }} />
                      }
                    </div>
                    <div className="spaceTitle">
                      <div className="creation">
                        <div className="dateWrapper">
                          <div className="date">
                            {constants.utils.parseDate(space.creation)}
                          </div>
                        </div>
                        {space.magic !== undefined && space.magic &&
                          <div className="magicAI">
                            AI
                          </div>
                        }
                        {space.files.length > 0 &&
                          <div className={`document${(space.metadata.youtube !== undefined && space.metadata.youtube !== '') ? ' andYoutube' : ''}`}>
                            <img
                              className='icon'
                              src={DocumentIcon}
                              alt="Document Attachment" />
                          </div>
                        }
                        {(space.metadata.youtube !== undefined && space.metadata.youtube !== '') &&
                          <div className="youtube">
                            <img
                              className='icon'
                              src={YoutubeIcon}
                              alt="YouTube Attachment" />
                          </div>
                        }
                        {(space.metadata.narchives !== undefined && space.metadata.narchives !== '') &&
                          <div className="narchives">
                            <img
                              className='icon'
                              src={NarchivesIcon}
                              alt="National Archives Attachment" />
                          </div>
                        }
                        {(space.metadata.figjam !== undefined && space.metadata.figjam !== '') &&
                          <div className="figjam">
                            <img
                              className='icon'
                              src={FigmaIcon}
                              alt="FigJam Attachment" />
                          </div>
                        }
                        {(space.metadata.gdrive !== undefined && space.metadata.gdrive !== '') &&
                          <div className="gdrive">
                            <img
                              className='icon'
                              src={GdriveIcon}
                              alt="Gdrive Attachment" />
                          </div>
                        }
                        {(space.metadata.canva !== undefined && space.metadata.canva !== '') &&
                          <div className="canva">
                            <img
                              className='icon'
                              src={CanvaIcon}
                              alt="Canva Attachment" />
                          </div>
                        }
                      </div>
                      {(space.creator.uuid !== store.profile.uuid && store.status.group.creator !== store.profile.uuid) &&
                        <div className="check">
                          <img
                            className={`checkImage${space.participants.map((participant) => participant.uuid).indexOf(store.profile.uuid) > -1 ? ' complete' : ''}`}
                            src={space.participants.map((participant) => participant.uuid).indexOf(store.profile.uuid) > -1 ? CheckMarked : Check}
                            alt="Done check" />
                        </div>
                      }
                      <div className="title">
                        {space.metadata.title}
                      </div>
                    </div>
                    <div className="participants">
                      <div className="mainAvatarWrapper">
                        <img
                          className="author"
                          src={CrownIcon}
                          alt="Original poster" />
                        {space.creator.image === undefined || space.creator.image === '' ?
                          <Participant
                            i={0}
                            participant={{
                              name: space.creator.name
                            }}
                            asset /> :
                          <div
                            className="mainAvatar"
                            style={{ backgroundImage: `url(${space.creator !== undefined && space.creator.image !== '' ? constants.services.s3ToCloudfrontMedia(space.creator.image) : DefaultAvatar})` }} />
                        }
                        <div className="mainName">
                          <div className="createdName">
                            {space.creator.name}
                          </div>
                          <div className="createdBy">
                            <div className="createdText">
                              {space.creator.role}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="viewResponses">
                        <div className="responsesText">
                          {(space.participants.length === 0 ? 0 : space.participants.length - 1)}
                        </div>
                        <img
                          className="responsesIcon"
                          src={ChatBubbleBlack}
                          alt="Responses" />
                      </div>
                    </div>
                  </button>
                  {((space.creator.uuid === store.profile.uuid) || store.status.group.creator === store.profile.uuid) &&
                    <div className="removeSpace">
                      <button
                        className="button"
                        onClick={(event) => props.removeSpace(space, event)}>
                        <img
                          className="delete"
                          src={DeleteIcon}
                          alt="Delete asset" />
                      </button>
                    </div>
                  }
                </div>
              </div>
            ))}
          </div> :
          <div className="emptySpace">
            <div className="noPrompts">
              <img
                className="noPromptsIcon"
                src={noPromptsIcon}
                alt="No discussions" />
            </div>
            {strings.default[store.language].Content[store.status.group.cid !== undefined && (store.status.community.cid !== store.status.group.cid) ? 'SelectAFolder' : 'NoPromptsYet']}
          </div>
        }
      </div>
    </div>
  );
}

export default SpacesView;
