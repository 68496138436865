import { forwardRef, useContext, useState, useRef, useImperativeHandle, useEffect } from 'react';
import { Context } from '../../DataStore';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

import Switch from '../common/Switch';
import Loading from '../common/Loading';
import ComboRecorder from './ComboRecorder';
import Uploader from './Uploader';

import ActionBar from './ActionBar';

import '../../styles/recorder/Recorder.scss';

const videoIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/videoIcon.svg`;
const screenIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/screenIcon.svg`;
const voiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/voiceIcon.svg`;
const comboIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/comboIcon.svg`;
const uploadIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/uploadIcon.svg`;
const InfoIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/info.svg`;

const lwvideoIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/lw-videoIcon.svg`;
const lwvoiceIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/lw-voiceIcon.svg`;

const Recorder = forwardRef((props, ref) => {
  const { store, dispatch } = useContext(Context);
  const {
    transcript,
    resetTranscript
  } = useSpeechRecognition();

  const [loadingCamera, setLoadingCamera] = useState(false);
  const [cameraStream, setCameraStream] = useState(window.MediaRecorder === undefined ? "" : new window.MediaRecorder(new MediaStream()));
  const [recordedVideoTime, setRecordedVideoTime] = useState('00:00');
  const [echoCancelled, setEchoCancelled] = useState(false);
  const [mediaRecorded, setMediaRecorded] = useState(false);
  const [pausedRecording, setPausedRecording] = useState(false);

  // New states
  const [queueing, setQueueing] = useState(false);
  const [countdown, setCountdown] = useState(3);

  // Playback states
  const [currentVideoTime, setCurrentVideoTime] = useState('00:00');

  const [loading, setLoading] = useState(false);

  // Check if WebRTC is enabled
  const webRTCEnabled = (window.MediaRecorder !== undefined && window.MediaSource !== undefined);

  // Originality Score
  const [score, setScore] = useState('');
  const [tone, setTone] = useState('');
  const [scoreGrade, setScoreGrade] = useState('green');
  const [infoHover, setInfoHover] = useState(false);
  const [toneHover, setToneHover] = useState(false);
  const [followUpHover, setFollowUpHover] = useState(false);
  const [questionTypeHover, setQuestionTypeHover] = useState(false);

  // Combo Recorder
  const [comboOn, setComboOn] = useState(false);

  // Uploader Status
  const uploaderOn = props.uploader !== undefined && props.uploader;

  const [fileType, setFileType] = useState(null);
  const [upload, setUpload] = useState(null);
  const [selected, setSelected] = useState(false);

  // Camera Selection
  const [availableCameras, setAvailableCameras] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState('');

  const fetchCameras = async () => {
    // Query all available media devices
    const devices = await navigator.mediaDevices.enumerateDevices();
    const videoDevices = devices.filter(device => device.kind === 'videoinput');
    setAvailableCameras(videoDevices);

    // Check if a camera was previously selected and saved
    const savedCameraId = localStorage.getItem('selectedCameraId');
    const defaultCamera = videoDevices.find(device => device.deviceId === savedCameraId);

    // Detect default camera or default to the first camera
    if (defaultCamera) {
      setSelectedCamera(savedCameraId);
    } else if (videoDevices.length > 0) {
      setSelectedCamera(videoDevices[0].deviceId);
    }
  };

  useEffect(() => {
    // If a camera is selected then store in localStorage
    if (selectedCamera) localStorage.setItem('selectedCameraId', selectedCamera);

    // Fetch cameras if the permission is granted
    navigator.permissions.query({ name: 'camera' }).then((permission) => {
      if (permission.state === 'granted') fetchCameras();
    });

    // Listener for when camera selection changes and remove previous listener
    navigator.mediaDevices.addEventListener('devicechange', fetchCameras);
    return () => navigator.mediaDevices.removeEventListener('devicechange', fetchCameras);
  }, []);

  // Document element references
  const refs = {
    scrollPosition: useRef(0),
    videoId: useRef(''),
    previewVideo: useRef(null),
    recordedVideo: useRef(null),
    echoCancel: useRef(null),
    recordStop: useRef(null),
    playPause: useRef(null),
    totalVideoTime: useRef('0:00'),
    audioMonitor: useRef(null),
    commentBox: useRef(null),
    mediaTitle: useRef(null),
    audioContext: useRef(null),
    seconds: useRef(0),
    timer: useRef(null),
    maxTimer: useRef(null),
    comboRecorderRef: useRef(null)
  }

  // Toggle DataStore activity
  const storeToggleRecording = (recording) => {
    const statusCopy = {
      ...store.status,
      recorder: {
        ...store.status.recorder,
        recording: recording,
      }
    }

    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  // Step 3: Initialize and success callback for stream
  const handleSuccess = (stream) => {
    window.stream = stream;
    refs.previewVideo.current.srcObject = stream;
    refs.previewVideo.current.play();

    let maxLevel = 0;
    let oldLevel = 0;
    let instantLevel = 0.0;

    if (refs.audioContext.current !== null) {
      refs.audioContext.current.close();
      refs.audioContext.current = null;
    }
    refs.audioContext.current = new AudioContext()

    const canvasContext = refs.audioMonitor.current.getContext('2d');
    const microphone = refs.audioContext.current.createMediaStreamSource(stream);
    const processor = refs.audioContext.current.createScriptProcessor(1024, 1, 1);

    microphone.connect(processor);
    processor.connect(refs.audioContext.current.destination);
    processor.onaudioprocess = (event) => {
      let inputLevel = event.inputBuffer.getChannelData(0);
      let sumLevel = 0.0;

      for (var i = 0; i < inputLevel.length; ++i) {
        sumLevel += inputLevel[i] * inputLevel[i];
      }

      instantLevel = Math.sqrt(sumLevel / inputLevel.length);
      maxLevel = Math.max(maxLevel, instantLevel);
      instantLevel = Math.max(instantLevel, oldLevel - 0.008);
      oldLevel = instantLevel;

      if (refs.audioMonitor.current !== null) {
        canvasContext.clearRect(0, 0, refs.audioMonitor.current.width, refs.audioMonitor.current.height);
        canvasContext.fillStyle = `rgba(${props.data.version && props.data.version === 'lw' ? '248,167,32' : '20,230,100'},0.8)`;
        canvasContext.fillRect(0, 0, (refs.audioMonitor.current.width) * (instantLevel / maxLevel), (refs.audioMonitor.current.height));
      }
    }

    setCameraStream(new MediaRecorder(window.stream, {
      mimeType: props.recordType.current === 'screen' ? 'video/webm; codecs=vp9,opus' : (constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : `video/webm; codecs=${constants.utils.isFirefox() ? 'vp8,opus' : 'avc1'}`)
    }));
    setRecordedVideoTime(store.status.modal.data.magicAI ? '00:00' : '01:00');
    props.setCameraStarted(true);

    const statusCopy = {
      ...store.status,
      recorder: {
        ...store.status.recorder,
        started: true,
      }
    }

    dispatch({
      type: 'status',
      data: statusCopy
    });

    setLoadingCamera(false);
    setLoading(false);

    if (props.recordType.current === 'screen' && !store.status.recorder.recording) {
      refs.recordStop.current.click();
    }

    return new Promise(resolve => refs.previewVideo.current.onplaying = resolve);
  }

  // Step 2: Initialize the camera
  const init = async (constraints) => {
    try {
      if (props.recordType.current === 'screen') {
        const cameraError = () => {
          stopCamera(false);
          setLoadingCamera(false);
          setLoading(false);
        };

        // Screen sharing logic
        navigator.mediaDevices.getDisplayMedia(constraints).then((screenStream) => {
          // Check for available microphones
          let microphones = 0;
          navigator.mediaDevices.enumerateDevices().then((devices) => {
            devices.forEach((device) => {
              if (device.kind === "audioinput") {
                microphones++;
              }
            });

            if (microphones === 0) {
              handleSuccess(screenStream);
            } else {
              // Add microphone to screen stream
              navigator.mediaDevices.getUserMedia({ audio: true }).then((microphoneStream) => {
                let composedStream = new MediaStream();

                // Add screen track
                screenStream.getVideoTracks().forEach((videoTrack) => {
                  composedStream.addTrack(videoTrack);
                });

                // System audio sharing
                if (screenStream.getAudioTracks().length) {
                  let context = new AudioContext();
                  let audioDestination = context.createMediaStreamDestination();

                  const systemSource = context.createMediaStreamSource(screenStream);
                  const systemGain = context.createGain();
                  systemGain.gain.value = 1.0;
                  systemSource.connect(systemGain).connect(audioDestination);

                  if (microphoneStream && microphoneStream.getAudioTracks().length) {
                    const microphoneSource = context.createMediaStreamSource(microphoneStream);
                    const microphoneGain = context.createGain();
                    microphoneGain.gain.value = 1.0;
                    microphoneSource.connect(microphoneGain).connect(audioDestination);
                  }

                  audioDestination.stream.getAudioTracks().forEach((audioTrack) => {
                    composedStream.addTrack(audioTrack);
                  });
                } else {
                  // Add only microphone audio
                  microphoneStream.getAudioTracks().forEach((microphoneTrack) => {
                    composedStream.addTrack(microphoneTrack);
                  });
                }

                handleSuccess(composedStream);
              }).catch(cameraError);
            }
          }).catch(cameraError);

          screenStream.getVideoTracks()[0].addEventListener('ended', () => {
            refs.recordStop.current.click();
          });
        }).catch(cameraError);
      } else {
        // Only set `deviceId` if `constraints.video` is defined
        if (constraints.video) {
          constraints.video.deviceId = selectedCamera ? { exact: selectedCamera } : undefined;
        }

        const userMedia = await navigator.mediaDevices.getUserMedia(constraints);
        handleSuccess(userMedia);
      }
    } catch (e) {
      console.error('Error initializing camera:', e);

      stopCamera(false);
      setLoadingCamera(false);
      setLoading(false);
    }
  };

  // Step 1: Start the recorder
  const startMultiRecorder = async (type) => {
    // Determine if it's an upload
    if (type === 'upload') {
      setFileType(null);
      setUpload(null);
      setSelected(false);

      props.setUploader(true);
      props.setCameraStarted(true);
    } else {
      setLoadingCamera(true);
      setLoading(true);

      props.setRecordType(type);

      let videoParams = null; // Initialize as `null` for voice-only recording
      switch (type) {
        case 'video':
          videoParams = {
            width: 800,
            height: 450
          };
          break;
        case 'voice':
          videoParams = null; // Omit video for voice
          break;
        case 'screen':
          videoParams = {
            cursor: 'always'
          };
          break;
        default:
          videoParams = {};
      }

      const constraints = {
        audio: {
          echoCancellation: true,
          noiseSuppression: true,
          sampleRate: 44100
        },
        ...(videoParams && { video: videoParams }) // Include `video` only if videoParams is not null
      };

      if (webRTCEnabled) {
        props.setRecordedBlobs([]);
        setLoading(true);

        // Initialize combo or regular recorder
        if (type === 'combo') {
          setComboOn(true);
        } else {
          await init(constraints);
        }
      } else {
        setLoadingCamera(false);
        const statusCopy = {
          ...store.status,
          message: {
            type: 'error',
            text: constants.strings.messages('error', 'camera')
          }
        }
        dispatch({
          type: 'status',
          data: statusCopy
        });
      }
    }
  }

  const pauseRecording = () => {
    clearInterval(refs.timer.current);
    setPausedRecording(true);
    cameraStream.pause();
  }

  const resumeRecording = () => {
    const pad = (value) => value > 9 ? value : `0${value}`;
    refs.timer.current = setInterval(() => {
      const videoTimeString = `${(store.status.modal.data.magicAI || (!store.status.modal.data.magicAI && refs.seconds.current < 60)) ? pad(parseInt(refs.seconds.current / 60, 10)) : '00'}:${pad((store.status.modal.data.magicAI ? ++refs.seconds.current : --refs.seconds.current) % 60)}`;
      setRecordedVideoTime(videoTimeString);
      refs.totalVideoTime.current = videoTimeString;
    }, 1000);
    if (!store.status.modal.data.magicAI) {
      refs.maxTimer.current = setTimeout(() => {
        clearInterval(refs.timer.current);
        stopRecording();
      }, 60000 * 1.0);
    }
    setPausedRecording(false);
    cameraStream.resume();
  }

  const stopCamera = (redo, setStore) => {
    if (uploaderOn) {
      props.setUploader(false);
      props.setUploaderType(false);
    }

    SpeechRecognition.stopListening();
    setScore('');
    setTone('');
    props.setFinalTranscript('');
    resetTranscript();

    if (window.stream !== undefined) {
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    const stoppedVideos = [refs.previewVideo.current, refs.recordedVideo.current];
    for (const video of stoppedVideos) {
      if (video !== null) {
        video.pause();
        video.srcObject = null;
        video.src = null;
        video.removeAttribute('src');
      }
    }
    setMediaRecorded(false);
    props.setCameraStarted(false);
    props.setRecordedBlobs([]);

    refs.seconds.current = 0;
    refs.timer.current = null;
    refs.maxTimer.current = null;

    setPausedRecording(false);

    if (setStore !== undefined && setStore) {
      const statusCopy = {
        ...store.status,
        recorder: {
          ...store.status.recorder,
          started: false,
        }
      }

      dispatch({
        type: 'status',
        data: statusCopy
      });
    }

    if (redo !== undefined && redo) {
      startMultiRecorder(props.recordType.current);
    } else {
      props.setRecordType('');
    }
  }

  const echoCancel = () => {
    if (window.stream !== null) {
      const cancellationChecked = refs.echoCancel.current.checked;
      window.stream.getTracks()[1].echoCancellation = cancellationChecked;
      setEchoCancelled(cancellationChecked);
    }
  }

  const countdownRecorder = () => {
    setQueueing(true);

    // Create a new audio object
    const countdownSound = new Audio(`${process.env.REACT_APP_CF_APP_ENDPOINT}mp3/ping.mp3`);

    const countdownAndBeep = (count) => {
      setCountdown(count);
      countdownSound.play();
    }

    countdownAndBeep(3);

    setTimeout(() => {
      countdownAndBeep(2);
      setTimeout(() => {
        countdownAndBeep(1);
        setTimeout(() => {
          setScore('');
          setTone('');
          props.setFinalTranscript('');
          resetTranscript();
          setQueueing(false);
          setCountdown(3);
          startRecording();
        }, 1000);
      }, 1000);
    }, 1000);
  }

  const renderBlobsPlayer = (recordedBlobBuffer) => {
    props.setRecordedBlobs(recordedBlobBuffer);

    // Combine all the video data from the buffer and set as source (to watch before submitting)
    const superBuffer = new Blob(recordedBlobBuffer, {
      type: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : `video/webm; codecs=${constants.utils.isFirefox() ? 'vp8,opus' : 'avc1'}`
    });
    refs.recordedVideo.current.srcObject = null;
    refs.recordedVideo.current.src = null;
    refs.recordedVideo.current.src = window.URL.createObjectURL(superBuffer);

    // Change UI displayed video time on video events
    refs.recordedVideo.current.ontimeupdate = () => {
      if (refs.recordedVideo.current !== null) {
        setCurrentVideoTime(new Date(refs.recordedVideo.current.currentTime * 1000).toISOString().substr(14, 5));
      }
    };
    refs.recordedVideo.current.onended = () => {
      refs.recordedVideo.current.currentTime = 0;
    };
  }

  const startRecording = () => {
    let recordedBlobBuffer = [];

    const handleDataAvailable = (event) => {
      if (event.data && event.data.size > 0) {
        recordedBlobBuffer.push(event.data);
      }
    }

    setCameraStream(new MediaRecorder(window.stream, {
      mimeType: constants.utils.isSafari() ? 'video/mp4; codecs=avc1' : `video/webm; codecs=${constants.utils.isFirefox() ? 'vp8,opus' : 'avc1'}`
    }));
    cameraStream.ondataavailable = handleDataAvailable;

    // Set video timers with maximums
    refs.seconds.current = store.status.modal.data.magicAI ? 0 : 60;
    const pad = (value) => value > 9 ? value : `0${value}`;
    refs.timer.current = setInterval(() => {
      const videoTimeString = `${(store.status.modal.data.magicAI || (!store.status.modal.data.magicAI && refs.seconds.current < 60)) ? pad(parseInt(refs.seconds.current / 60, 10)) : '00'}:${pad((store.status.modal.data.magicAI ? ++refs.seconds.current : --refs.seconds.current) % 60)}`;
      setRecordedVideoTime(videoTimeString);
      refs.totalVideoTime.current = videoTimeString;
    }, 1000);

    if (!store.status.modal.data.magicAI) {
      refs.maxTimer.current = setTimeout(() => {
        clearInterval(refs.timer.current);
        stopRecording();
      }, 60000 * 1.0);
    }

    // Recorder Stopped
    cameraStream.onstop = () => {
      clearInterval(refs.timer.current);
      clearInterval(refs.maxTimer.current);

      renderBlobsPlayer(recordedBlobBuffer);
    };

    cameraStream.onstart = () => {
      setCameraStream(cameraStream);
    }
    // Set the buffer chunk size on start of stream recording
    cameraStream.start(10);

    if (store.status.modal.data.magicAI) {
      if (SpeechRecognition.browserSupportsSpeechRecognition()) {
        SpeechRecognition.startListening({
          continuous: true,
          language: props.speaking
        });

        if (props.speaking === 'en-US') {
          SpeechRecognition.startListening({ continuous: true });
        } else {
          SpeechRecognition.startListening({
            continuous: true,
            language: props.speaking
          });
        }
      }
    }

    storeToggleRecording(true);
    setMediaRecorded(false);
  }

  const processTranscript = async (assignedTranscript) => {
    if (assignedTranscript.length) {
      try {
        let data = {
          uuid: store.profile.uuid,
          sessionId: store.session.sessionId,
          language: props.speaking,
          transcript: constants.utils.cleanText(assignedTranscript)
        }
        const url = `${constants.services.url.api}/authenticity/score/`;
        const response = await fetch(url, constants.services.config(data));
        const responseData = await response.json();

        if (response.ok) {
          if (responseData.status === 'Success') {
            const originalityData = responseData.data.originality.documents[0];

            const averageScore = originalityData.average_generated_prob;
            const returnedScore = originalityData.completely_generated_prob;
            let score = (
              (returnedScore < 0.12 && averageScore === 0) ? 100 :
                (returnedScore < 0.82 && averageScore === 1) ? 0 :
                  (100 - (returnedScore * 100)));

            const toneData = responseData.data.sentiment;
            const transcriptData = responseData.data.transcript;
            const keywordsData = responseData.data.keywords;

            switch (true) {
              case score === 0:
                setScoreGrade('clear');
                break;
              case score <= 40:
                setScoreGrade('red');
                break;
              case score <= 60:
                setScoreGrade('orange');
                break;
              case score <= 80:
                setScoreGrade('yellow');
                break;
              default:
                setScoreGrade('green');
            }

            setScore(Math.floor(score));
            setTone(toneData);
            props.setFinalTranscript(props.speaking !== 'en-US' ? data.transcript : (transcriptData.length ? transcriptData : constants.utils.cleanText(assignedTranscript)));
            props.setFinalKeywords(keywordsData);
          } else {
            props.sendMessage(constants.strings.messages('error', 'network'));
          }
        } else {
          props.sendMessage(constants.strings.messages('error', 'network'));
        }
      } catch (error) {
        props.sendMessage(constants.strings.messages('error', 'network'));
      }
    } else {
      setScore(100);
      setScoreGrade('green');
      setTone({
        positive: 0.5,
        negative: 0.5
      });
      props.setFinalTranscript(assignedTranscript);
    }
  }

  const stopRecording = async () => {
    if (comboOn) {
      refs.comboRecorderRef.current.stopRecordingCombo();
    } else {
      if (SpeechRecognition.browserSupportsSpeechRecognition()) {
        SpeechRecognition.stopListening();
      }

      cameraStream.stop();
      window.stream.getTracks().forEach((track) => {
        track.stop();
      });
      storeToggleRecording(false);
      setMediaRecorded(true);

      if (store.status.modal.data.magicAI) {
        if (SpeechRecognition.browserSupportsSpeechRecognition()) {
          props.setFinalTranscript(transcript);
          processTranscript(transcript);
        }
      } else {
        // TO-DO: leave some kind of message not available
      }

      const doneSound = new Audio(`${process.env.REACT_APP_CF_APP_ENDPOINT}mp3/done.mp3`);
      doneSound.play();

      const statusCopy = {
        ...store.status,
        recorder: {
          ...store.status.recorder,
          started: false,
          recording: false
        }
      }

      dispatch({
        type: 'status',
        data: statusCopy
      });
    }
  }

  const playPauseMedia = (play) => {
    (play ? refs.recordedVideo.current.play() : refs.recordedVideo.current.pause());
  };

  const redoRecording = () => {
    setScore('');
    setTone('');
    props.setFinalTranscript('');
    resetTranscript();

    if (refs.recordedVideo.current !== null) {
      if (!refs.recordedVideo.current.paused) {
        playPauseMedia(false);
      }
      refs.recordedVideo.current.pause();
      refs.recordedVideo.current.srcObject = null;
      refs.recordedVideo.current.src = null;
      stopCamera(true);
    }
  };

  // Controls Object
  const controls = [
    {
      className: 'playerButton',
      disabled: !mediaRecorded,
      onClick: () => redoRecording(),
      text: 'Redo'
    },
    {
      className: 'playerButton',
      disabled: !props.cameraStarted || queueing || loadingCamera || store.status.recorder.recording,
      onClick: () => stopCamera(false, true),
      text: 'Camera Off'
    }
  ]

  // Button image generators
  const generateIcon = (type) => {
    switch (type) {
      case 'video':
        return props.data.version && props.data.version === 'lw' ? lwvideoIcon : videoIcon;
      case 'voice':
        return props.data.version && props.data.version === 'lw' ? lwvoiceIcon : voiceIcon;
      case 'screen':
        return screenIcon;
      case 'combo':
        return comboIcon;
      case 'upload':
        return uploadIcon;
      case 'mirror':
        return videoIcon;
      default:
        return ''
    }
  }

  const buildButton = (action) => {
    switch (action) {
      case 'Record':
        return (
          <div className="iconWrapper">
            <div className="icon record"></div>
          </div>
        )
      case 'Stop':
        return (
          <div className="iconWrapper">
            <div className="icon stop"></div>
          </div>
        )
      case 'Play':
        return (
          <div className="iconWrapper">
            <div className="icon play"></div>
          </div>
        )
      case 'Pause':
        return (
          <div className="iconWrapper">
            <div className={`icon pauseWrapper${pausedRecording ? ' paused' : ''}`}>
              <div className='lineWrapper'>
                <div className="pause"></div>
              </div>
              <div className='lineWrapper'>
                <div className="pause"></div>
              </div>
            </div>
          </div>
        )
      case 'Redo':
        return (
          <div className="iconWrapper">
            <div className="icon redoWrapper">
              <div className="redoCircle"></div>
              <div className="redoBlock"></div>
              <div className="redoTriangle"></div>
            </div>
          </div>
        )
      case 'Camera Off':
        return (
          <div className="iconWrapper">
            <div className="icon cameraOffWrapper">
              <div className="offDevice">
                <div className="delete">
                  <div className="plus vertical"></div>
                  <div className="plus horizontal"></div>
                </div>
              </div>
            </div>
          </div>
        )
      case 'Submit':
        return (
          <div className="iconWrapper">
            <div className="icon submitWrapper">
              <div className="confirmWrapper">
                <div className="checkmarkLong"></div>
                <div className="checkmarkShort"></div>
              </div>
            </div>
          </div>
        )
      case 'PauseResume':
        return (
          <div className="iconWrapper">
            <div className="icon">
              {`${pausedRecording ? strings.default[store.language].Recorder.Resume : strings.default[store.language].Recorder.Pause} ${strings.default[store.language].Recorder.Recording}`}
            </div>
          </div>
        )
      default:
        return action;
    }
  }

  const buttons = !props.mirrorMode ?
    (constants.utils.isMobile() ? [
      { className: 'recorderCameraStart', type: 'video' },
      { className: 'recorderVoiceStart', type: 'voice' }
    ] : store.status.modal.data.magicAI ? [
      { className: 'recorderCameraStart', type: 'video' },
      { className: 'recorderVoiceStart', type: 'voice' },
      { className: 'recorderScreenStart', type: 'screen' },
      { className: 'recorderComboStart', type: 'combo' },
      { className: 'recorderUploadStart', type: 'upload' }
    ] : [
      { className: 'recorderCameraStart', type: 'video' },
      { className: 'recorderVoiceStart', type: 'voice' },
      { className: 'recorderScreenStart', type: 'screen' }
    ]) : [
      { className: 'recorderCameraStart', type: 'video' }
    ]

  const determineSentiment = (tone) => {
    switch (true) {
      case ((tone.positive >= 0.4 && tone.positive < 0.6)):
        return strings.default[store.language].Recorder.Neutral;
      case (tone.positive >= 0.6):
        return strings.default[store.language].Recorder.Positive;
      case (tone.positive < 0.4):
        return strings.default[store.language].Recorder.Negative;
      default:
        return strings.default[store.language].Recorder.Neutral;
    }
  }

  useImperativeHandle(ref, () => ({
    closeRecorder() {
      stopCamera();
    }
  }));

  const resetComboRecorder = () => {
    stopCamera(false);
    setLoadingCamera(false);
    setLoading(false);
    setComboOn(false);
  };

  const questionTypes = [
    "expanding",
    "opposing",
    "imaginative"
  ]

  // Setting uploaded file
  const setFileManager = () => {
    props.setUploaderType(true);

    setMediaRecorded(true);
    setLoading(true);

    const reader = new FileReader();

    reader.readAsArrayBuffer(upload);
    reader.onloadend = () => {
      const arrayBuffer = reader.result;
      renderBlobsPlayer([arrayBuffer]);
      setLoading(false);
    };
  }

  return (
    <div className={`Recorder${props.className ?? ''}${props.mirrorMode ? ' mirrorMode' : ''}`}>

      <Loading active={loading} />

      {/* Video Recorder */}
      {comboOn ?
        <ComboRecorder
          ref={refs.comboRecorderRef}
          loading={loading}
          controls={controls}
          mediaRecorded={mediaRecorded}
          setLoading={(loading) => setLoading(loading)}
          setCameraStarted={(started) => {
            props.setCameraStarted(started);
            setLoadingCamera(false);
          }}
          setBuildButton={(button) => buildButton(button)}
          resetComboRecorder={() => resetComboRecorder()}
          setRecordedBlobs={(recordedBlobBuffer) => {
            setMediaRecorded(true);
            renderBlobsPlayer(recordedBlobBuffer)
          }}
        /> :
        uploaderOn ?
          <div className="uploaderWrapper">
            <Uploader
              fileType={fileType}
              setFileType={(type) => setFileType(type)}
              upload={upload}
              setUpload={(file) => setUpload(file)}
              selected={selected}
              setSelected={(state) => setSelected(state)}
              setFile={() => setFileManager()} />
          </div> :
          <div className={`recorderCamera${props.recordType.current === 'video' ? ' mirrored' : ''}`}>
            <video
              className={`previewVideo ${props.recordType.current}`}
              ref={refs.previewVideo}
              playsInline
              muted />
            {!props.cameraStarted ?
              <div className={`recorderWrapper${loadingCamera ? ' disabled' : ''}`}>
                {buttons.map((button, i) => (
                  <div
                    className="recorderButtonWrapper"
                    key={i}>
                    {(button.type === 'combo' || button.type === 'upload') &&
                      <div className="betaTag">
                        {strings.default[store.language].Recorder.Beta}
                      </div>
                    }
                    <button
                      className={`recorderButton ${button.className}`}
                      onClick={() => startMultiRecorder(button.type)}>
                      <div className="contentWrapper">
                        <div className="mediaType">
                          <img
                            className="mediaIndicator"
                            src={generateIcon(button.type)}
                            alt={`${constants.utils.capitalizeFirst(button.type)} Icon`} />
                        </div>
                        <div className="buttonTitle">
                          {`${strings.default[store.language].Recorder[constants.utils.capitalizeFirst(button.type)]}`}
                        </div>
                      </div>
                    </button>
                  </div>
                ))}
              </div> :
              ''
            }
          </div>
      }

      {(props.cameraStarted && !mediaRecorded && !props.mirrorMode && !comboOn && !uploaderOn) &&
        <>
          <div className="recordControls">
            <div className="recControlWrapper">
              <button
                className="recControl pause"
                disabled={!store.status.recorder.recording}
                onClick={() => pausedRecording ? resumeRecording() : pauseRecording()}>
                {buildButton('Pause')}
                <div className="textWrapper">
                  {buildButton('PauseResume')}
                </div>
              </button>
            </div>
          </div>
          {(props.recordType.current !== 'voice' && props.recordType.current !== 'screen') &&
            <div className="cameraSelector">
              <select
                className="cameraSelect"
                value={selectedCamera}
                onChange={(e) => setSelectedCamera(e.target.value)}
                disabled={!props.cameraStarted || loadingCamera || mediaRecorded}>
                {availableCameras.map((camera, index) => (
                  <option key={index} value={camera.deviceId}>
                    {camera.label || `Camera ${index + 1}`}
                  </option>
                ))}
              </select>
            </div>
          }
        </>
      }

      {(!props.mirrorMode && !comboOn) &&
        <>
          {/* Time and Status */}
          <div className={`timeWrapper${!props.cameraStarted || loadingCamera || mediaRecorded ? '' : props.mirrorMode ? '' : uploaderOn ? '' : ' active'}${props.data.version ? ' embed' : ''}`}>
            <div className="recorderStatus">
              <div className={`statusShape${store.status.recorder.recording ? ' recording' : ' idle'}`} />
            </div>
            <div className={`recorderTime${mediaRecorded ? '' : ' active'}`}>
              {recordedVideoTime}
            </div>
            <div className={`playerTime${mediaRecorded ? ' active' : ''}`}>
              <div className="playTime">
                {currentVideoTime}
              </div>
              <div className="playDivider">
                |
              </div>
              <div className="totalTime">
                {refs.totalVideoTime.current}
              </div>
            </div>
          </div>
          {/* Microphone Monitor */}
          <div className={`monitorWrapper${props.cameraStarted && !mediaRecorded && !props.mirrorMode ? uploaderOn ? '' : ' active' : ''}${props.data.version ? ' embed' : ''}`}>
            <div className="voiceMicrophone">
              <div className="circleWrapper">
                <div className="circle small"></div>
              </div>
              <div className="circleWrapper">
                <div className="circle medium"></div>
              </div>
              <div className="circleWrapper">
                <div className="circle large"></div>
              </div>
            </div>
            <div className="volumeWrapper">
              <canvas
                className="volumeMonitor"
                ref={refs.audioMonitor}></canvas>
            </div>
          </div>

          <div className="buttonControls">
            {!uploaderOn &&
              <div className="buttonWrapper">
                <button
                  className="playerButton"
                  ref={refs.recordStop}
                  disabled={!props.cameraStarted || queueing || loadingCamera || mediaRecorded}
                  onClick={() => store.status.recorder.recording ? stopRecording() : countdownRecorder()}>
                  {buildButton(store.status.recorder.recording ? 'Stop' : 'Record')}
                </button>
              </div>
            }

            {controls.map((control, i) => {
              if (control.text === 'Redo' && uploaderOn) {
                return ''
              } else {
                return (
                  <div
                    className="buttonWrapper"
                    key={i}>
                    <button
                      className={control.className}
                      disabled={control.disabled}
                      onClick={control.onClick}>
                      {buildButton(control.text)}
                    </button>
                  </div>
                )
              }
            })}
          </div>
        </>
      }

      {queueing &&
        <div className="queueingWrapper">
          <div className="countdownWrapper">
            <div className="countdownNumber">
              {countdown}
            </div>
          </div>
        </div>
      }

      {(!props.mirrorMode && store.status.modal.data.magicAI) &&
        <>
          <div className="originalityWrapper transcribeWrapper">
            <div className="originalityTitle">
              {strings.default[store.language].Recorder.Speaking}
            </div>
            <select
              className="translateSelect"
              onChange={(event) => props.setSpeaking(constants.services.translateLanguages.find(lang => lang.label === event.target.value).value)}
              defaultValue="en-US"
              value={constants.services.translateLanguages.find(lang => lang.value === props.speaking).label}>
              {constants.services.translateLanguages.map((language, i) => (
                <option key={i}>
                  {language.label}
                </option>
              ))}
            </select>
          </div>
          <div className="originalityWrapper">
            <div className="originalityInfo">
              {infoHover &&
                <div className="infoBubble">
                  {strings.default[store.language].Recorder.InfoHover}
                  <div className="triangle" />
                </div>
              }
              <div className="info">
                <img
                  src={InfoIcon}
                  className="infoIcon"
                  alt="More info"
                  onMouseEnter={() => setInfoHover(true)}
                  onMouseLeave={() => setInfoHover(false)} />
              </div>
            </div>
            <div className="originalityTitle">
              {strings.default[store.language].Recorder.Originality}
            </div>
            <div className="originalityScore">
              <div className="scoreStatus">
                {store.status.recorder.recording ?
                  <div className="dotContainer">
                    <div className="dot dot1" />
                    <div className="dot dot2" />
                    <div className="dot dot3" />
                  </div> : ''
                }
                {score !== '' ?
                  <div className="scoreWrapper">
                    <div
                      className={`scoreBar ${scoreGrade}`}
                      style={{ width: `${score}px` }} />
                    <div className={`scoreNumber score-${score}`}>
                      {`${score}%`}
                    </div>
                  </div> : ''
                }
              </div>
            </div>
          </div>

          <div className="originalityWrapper toneWrapper">
            <div className="originalityInfo">
              {toneHover &&
                <div className="infoBubble toneBubble">
                  {strings.default[store.language].Recorder.ToneHover}
                  <div className="triangle" />
                </div>
              }
              <div className="info">
                <img
                  src={InfoIcon}
                  className="infoIcon"
                  alt="More info"
                  onMouseEnter={() => setToneHover(true)}
                  onMouseLeave={() => setToneHover(false)} />
              </div>
            </div>
            <div className="originalityTitle">
              {strings.default[store.language].Recorder.Tone}
            </div>
            <div className="originalityScore toneScore">
              <div className="scoreStatus">
                {store.status.recorder.recording ?
                  <div className="dotContainer">
                    <div className="dot dot1" />
                    <div className="dot dot2" />
                    <div className="dot dot3" />
                  </div> : ''
                }
                {tone !== '' ?
                  <div className="scoreWrapper">
                    <div
                      className={`toneBar green positive
                ${determineSentiment(tone) === strings.default[store.language].Recorder.Neutral ? ' white' : ''}
                ${tone.positive === 1 ? ' full' : ''}
                ${tone.positive === 0 ? ' empty' : ''}`}
                      style={{ width: `${tone.positive * 100}px` }} />
                    <div
                      className={`toneBar red negative
                ${determineSentiment(tone) === strings.default[store.language].Recorder.Neutral ? ' white' : ''}
                ${tone.negative === 1 ? ' full' : ''}
                ${tone.negative === 0 ? ' empty' : ''}`}
                      style={{ width: `${tone.negative * 100}px` }} />
                    <div className="scoreNumber scoreTone">
                      {determineSentiment(tone)}
                    </div>
                  </div> : ''
                }
              </div>
            </div>
          </div>

          {(props.data.isSpace !== undefined && props.data.isSpace && !uploaderOn) &&
            <>
              <div className="originalityWrapper followUp">
                <div className="originalityInfo">
                  {followUpHover &&
                    <div className="infoBubble toneBubble">
                      {strings.default[store.language].Recorder.FollowUpHover}
                      <div className="triangle" />
                    </div>
                  }
                  <div className="info">
                    <img
                      src={InfoIcon}
                      className="infoIcon"
                      alt="More info"
                      onMouseEnter={() => setFollowUpHover(true)}
                      onMouseLeave={() => setFollowUpHover(false)} />
                  </div>
                </div>
                <div className="originalityTitle">
                  <div className="keywordAI">
                    AI
                  </div>
                  <div className="keywordWord">
                    {strings.default[store.language].Recorder.FollowUp}
                  </div>
                </div>
                {props.data.followUp !== undefined &&
                  <div className="originalityScore followUp">
                    <Switch
                      boolean={props.data.followUp}
                      onClick={() => props.setFollowUp()} />
                  </div>
                }
              </div>

              {props.data.followUp &&
                <div className="originalityWrapper transcribeWrapper questionType">
                  <div className="originalityInfo">
                    {questionTypeHover &&
                      <div className="infoBubble toneBubble">
                        {strings.default[store.language].Recorder.QuestionTypeHover}
                        <div className="triangle" />
                      </div>
                    }
                    <div className="info">
                      <img
                        src={InfoIcon}
                        className="infoIcon"
                        alt="More info"
                        onMouseEnter={() => setQuestionTypeHover(true)}
                        onMouseLeave={() => setQuestionTypeHover(false)} />
                    </div>
                  </div>
                  <div className="originalityTitle">
                    {strings.default[store.language].Recorder.QuestionType}
                  </div>
                  <select
                    className="translateSelect"
                    onChange={(event) => props.setQuestionType((event.target.value).toLowerCase())}
                    defaultValue="expanding"
                    value={constants.utils.capitalizeFirst(props.data.questionType)}>
                    {questionTypes.map((type, i) => (
                      <option key={i}>
                        {constants.utils.capitalizeFirst(type)}
                      </option>
                    ))}
                  </select>
                </div>
              }
            </>
          }
        </>
      }

      <div className={`echoCancellation${props.cameraStarted ? ' active' : ''}`}>
        <span className="echoText">
          Echo cancellation:
        </span>
        <input
          className="echoCancel"
          type="checkbox"
          ref={refs.echoCancel}
          disabled={!props.cameraStarted || loadingCamera || mediaRecorded}
          checked={echoCancelled}
          onChange={() => { echoCancel() }} />
      </div>
      <div className={`recordedVideoWrapper${mediaRecorded ? ' showing' : ''}`}>
        <video
          className="recordedVideo"
          ref={refs.recordedVideo}
          playsInline
          controls={true} />
      </div>

      <ActionBar onClose={() => stopCamera(false, true)} />
    </div>
  )
});

export default Recorder; 