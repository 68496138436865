import { useContext, useRef, useEffect, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Context } from '../../DataStore';

import Loading from '../common/Loading';
import AssetsView from '../composite/AssetsView';

import * as constants from '../exports/constants';

import '../../styles/embed/Viewer.scss';

const GravityLogo = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gravity-logo.svg`;

const Viewer = () => {
    const { store, dispatch } = useContext(Context);
    const history = useHistory();
    const params = useParams();
    const location = useLocation();

    const fetching = useRef(false);
    const fetched = useRef(false);

    const fetchData = useCallback(async (sid) => {
        try {
            const response = await constants.services.configParams('/asset/embeddable/', { sid: sid });
            const responseData = await response.json();

            fetching.current = false;
            fetched.current = true;

            if (response.ok) {
                let dataCopy = {
                    ...responseData.data,
                    metadata: responseData.data.data
                }
                delete dataCopy.data
                console.log(dataCopy);

                dispatch({
                    type: 'store',
                    data: {
                        ...store,
                        assets: responseData,
                        status: {
                            ...store.status,
                            space: dataCopy
                        },
                        viewer: {
                            active: true
                        }
                    }
                });
            } else { history.push("/login/") }
        } catch (_) { history.push("/login/") }
    }, []);

    const goHome = () => {
        if (location.pathname !== '/dashboard/') {
            history.push('/dashboard/');
            window.location.reload();
        }
    }

    useEffect(async () => {
        if (!fetched.current && !fetching.current) {
            fetching.current = true;

            dispatch({
                type: 'viewer',
                data: {
                    active: true
                }
            });

            const response = await constants.services.configParams('/space/fetch/embeddable/', { sid: params?.spaceId });
            const responseData = await response.json();

            if (response.ok && responseData.embeddable) {
                fetchData(params?.spaceId);
            } else { history.push("/login/") }
        }
    }, [fetchData]);

    return (
        <div className="Viewer">
            <div className="banner">
                <button
                    className="gravityHome"
                    onClick={() => goHome()}>
                    <img
                        className="gravityLogo"
                        src={GravityLogo}
                        alt="Gravity logo" />
                </button>
            </div>
            {fetched.current ?
                <AssetsView
                    closing={false}
                    removeSpace={() => null}
                    assetSettings={() => null} /> :
                <div className="loadingViewer">
                    <div className="loaderWrapper">
                        <img
                            className="gravityLogo"
                            src={GravityLogo}
                            alt="Gravity logo" />
                        <Loading active={true} />
                    </div>
                </div>
            }
        </div>
    );
}

export default Viewer;
