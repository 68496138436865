import { useContext, useState, useRef, useEffect, useCallback } from 'react';
import { Context } from '../../DataStore';
import { useParams } from 'react-router-dom';

import { GoogleOAuthProvider } from '@react-oauth/google';
import Google from '../common/Google';

import Recorder from '../recorder/Recorder';
import Loading from '../common/Loading';

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/embed/PublicRecorder.scss';

const GravityLogo = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gravity-logo.svg`;
const GravityLogoWhite = `${process.env.REACT_APP_CF_MKT_ENDPOINT}svg/gravity-logo-white.svg`;
const LitworldLogo = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/litworld-logo.png`;

const LitworldBluebird = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/litworld/bluebird.png`;
const GravityTriangle = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/gravity-square-large.png`;

const openIcon = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/open.svg`;

const PublicRecorder = () => {
    const { store } = useContext(Context);
    const params = useParams();

    // Restoring Session
    const [restoring, setRestoring] = useState(false);
    const [loginTempRegister, setLoginTempRegister] = useState(false);

    const [gravityLogin, setGravityLogin] = useState(false);

    // Video Uploading
    const [cameraStarted, setCameraStarted] = useState(false);
    const [recordedBlobs, setRecordedBlobs] = useState([]);
    const [recorderSuccess, setRecorderSuccess] = useState(false);

    // Transcript
    const [finalTranscript, setFinalTranscript] = useState('');
    const [finalKeywords, setFinalKeywords] = useState('');

    // Video Uploader
    const [uploader, setUploader] = useState(false);
    const [uploaderType, setUploaderType] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploadComplete, setUploadComplete] = useState(false);

    // Recorder refs
    const recordType = useRef('');
    const recorderRef = useRef(null);

    // Fetching asset data
    const [assetData, setAssetData] = useState(null);
    const [hide, setHide] = useState(false);

    const fetching = useRef(false);
    const fetched = useRef(false);

    // Input fields
    const [nameEntry, setNameEntry] = useState('');
    const [emailEntry, setEmailEntry] = useState('');
    const [passwordEntry, setPasswordEntry] = useState('');

    const [submitValid, setSubmitValid] = useState(false);

    // Details
    const [uuid, setUuid] = useState(constants.utils.randomAid(10));
    const [restoreAttempt, setRestoreAttempt] = useState(false);

    const [embeddable, setEmbeddable] = useState(false);
    const gravityRestore = useRef(false);

    const [viewPrompt, setViewPrompt] = useState(false);
    const videoVideo = useRef(false);

    // FUNCTION: Google login
    const submitSocial = async (socialLogin) => {
        if (socialLogin !== undefined && socialLogin) {
            localStorage.setItem('gravitySession', JSON.stringify(socialLogin.session));

            setNameEntry(socialLogin.user.name);
            setEmailEntry(socialLogin.user.email);
            setUuid(socialLogin.user.uuid);

            setRestoring(false);
            setLoginTempRegister(false);
            gravityRestore.current = true;
        }
    }

    // FUNCTION: Restore session
    const restoreSession = async (localSession) => {
        const response = await constants.services.configPost('/user/restore/', localSession);
        const responseData = await response.json();

        setRestoring(false);

        if (response.ok && responseData.status === 'Success') {
            localStorage.setItem('gravitySession', JSON.stringify(responseData.session));

            setNameEntry(responseData.user.name);
            setEmailEntry(responseData.user.email);
            setUuid(responseData.user.uuid);

            setRestoring(false);
            setLoginTempRegister(false);
            gravityRestore.current = true;
        }
    };

    // FUNCTION: Fetching main data for recorder
    const fetchRecorder = useCallback(async () => {
        // TO-DO: wait for new uuid and now save to new gravityTempSession
        const response = await constants.services.configParams('/space/fetch/recorder/', { sid: params?.spaceId, uuid: uuid });
        const responseData = await response.json();

        fetching.current = false;
        fetched.current = true;

        setEmbeddable(responseData.status === 'Success');

        if (responseData.status === 'Success') {
            document.querySelector('.recorderWrapper').style.removeProperty('display');
            document.querySelector('.buttonControls').style.removeProperty('display');
            setAssetData(responseData.data);
        }

        if (!restoreAttempt) setRestoring(false);

    }, [params?.spaceId]);

    // FUNCTION: Upload new video to s3 API
    const submitMedia = async () => {
        const localSession = JSON.parse(localStorage.getItem('gravitySession'));
        const localSessionValid = (gravityRestore.current && localSession !== undefined && localSession !== null);
        setUploading(true);

        // Basic data for asset identification
        const aid = constants.utils.randomAid(10);
        const date = Date().toLocaleString([], {
            month: '2-digit',
            year: '4-digit',
            hour: '2-digit',
            minute: '2-digit'
        });
        const dateArr = date.split(' (');
        const name = dateArr[0].replace(/[\s:]/g, '_');

        // TO-DO: take passed-in type or determine if uploader is mp4
        const safariOrUploader = (constants.utils.isSafari() || uploaderType);
        const videoType = safariOrUploader ? 'video/mp4' : 'video/webm';
        const videoName = `${aid}_${name}.${safariOrUploader ? 'mp4' : 'webm'}`;

        let data = {
            cid: assetData.cid,
            gid: assetData.gid,
            sid: assetData.sid,
            aid: aid,
            uuid: localSessionValid ? localSession.uuid : uuid,
            sessionId: localSessionValid ? localSession.sessionId : 'MODAL',
            title: '',
            description: `MODAL - ${constants.utils.cleanText(nameEntry)}`,
            type: uploaderType ? 'video' : recordType.current,
            transcript: finalTranscript,
            keywords: finalKeywords,
            links: [], spaceType: null,
            youtube: '', narchives: '', figjam: '', gdrive: '', canva: '',
            extension: safariOrUploader ? 'mp4' : 'webm',
            magic: assetData.magic,
            premium: true,
            private: false,
            publishDate: new Date(),
            followUp: false,
            questionType: null,
            location: '',
            originalname: videoName,
            codecs: constants.utils.isFirefox() ? 'vp8,opus' : 'avc1',
            speaking: 'en-US',
            relation: {
                space: assetData.sid,
                response: assetData.sid,
                thread: null
            }
        }

        // TO-DO: change it up so if file the doesn't create this object
        const superBuffer = new Blob(recordedBlobs, { type: safariOrUploader ? 'video/mp4; codecs=avc1' : `video/webm; codecs=${constants.utils.isFirefox() ? 'vp8,opus' : 'avc1'}` });
        const fileType = new File([superBuffer], `${aid}_${name}.${safariOrUploader ? 'mp4' : 'webm'}`, { type: safariOrUploader ? 'video/mp4; codecs=avc1' : `video/webm; codecs=${constants.utils.isFirefox() ? 'vp8,opus' : 'avc1'}` });

        try {
            let urlData = {
                uuid: uuid,
                sessionId: localSessionValid ? localSession.sessionId : 'MODAL',
                fileName: videoName,
                fileType: videoType
            }

            const url = `${constants.services.url.api}/asset/generate/presigned-url/`;
            const response = await fetch(url, constants.services.config(urlData));
            const responseData = await response.json();

            if (!response.ok || responseData.status !== 'Success') throw new Error('network');

            // Direct upload to s3 with presigned URL
            const uploadResponse = await fetch(responseData.uploadUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': videoType
                },
                body: fileType
            });

            if (!uploadResponse.ok) throw new Error('network');

            data.location = (uploadResponse.url).split('?')[0];

            let createData = {
                uuid: uuid,
                sessionId: localSessionValid ? localSession.sessionId : 'MODAL',
                metadata: data
            }

            const createUrl = `${constants.services.url.api}/asset/create/`;
            const createResponse = await fetch(createUrl, constants.services.config(createData));
            const createResponseData = await createResponse.json();

            if (!createResponse.ok || (createResponseData.status !== 'Success' && createResponseData.status !== 'Processing')) throw new Error(createResponseData.status || 'network');

            setCameraStarted(false);
            setUploading(false);
            setRecorderSuccess(true);

            // Show the complete screen
            setUploadComplete(true);

            // Hide the buttons
            document.querySelector('.buttonControls').style.display = 'none';
        } catch (error) {
            setUploading(false);
            setUploadComplete(false);
        }
    }

    const renderLogo = (provider) => {
        switch (provider) {
            case 'g':
                return (
                    <a
                        className="gravityLink"
                        href="https://usegravity.io"
                        target="_blank"
                        rel="noreferrer">
                        <img
                            className="gravityLogo"
                            src={GravityLogo}
                            alt="Gravity Logo" />
                    </a>
                );
            case 'lw':
                return (
                    <>
                        <img
                            className="litworldLogo"
                            src={LitworldLogo}
                            alt="LitWorld Logo" />
                        <div className="poweredBy">
                            Powered by
                        </div>
                        <a
                            className="gravityLink"
                            href="https://usegravity.io"
                            target="_blank"
                            rel="noreferrer">
                            <img
                                className="gravityLogo"
                                src={GravityLogo}
                                alt="Gravity Logo" />
                        </a>
                    </>
                );
            default:
                return null;
        }
    }

    const checkValidEntry = (entry, type) => {
        const name = (type === 'name' ? entry : nameEntry);
        const email = (type === 'email' ? entry : emailEntry);
        const password = (type === 'password' ? entry : passwordEntry);

        setSubmitValid(gravityLogin ? (password.length && constants.validate.email(email)) : (name.length && constants.validate.email(email)));
    }

    const setGravityLoginManager = (state) => {
        setGravityLogin(state);
        checkValidEntry('', '');
        if (state && !restoreAttempt) {
            setRestoreAttempt(true);

            if (localStorage.getItem('gravitySession') !== undefined) {
                const localSession = JSON.parse(localStorage.getItem('gravitySession'));
                if (localSession !== null) {
                    restoreSession(localSession);
                };
            }
        }
    }

    const submitDetails = async () => {
        if (assetData !== null) {
            const url = `${constants.services.url.api}/user/${gravityLogin ? 'login' : 'create/temp'}/`;
            const response = await fetch(url, constants.services.config(gravityLogin ? { email: emailEntry, password: passwordEntry } : { name: nameEntry, email: emailEntry }));
            let responseData = await response.json();

            if (!response.ok || responseData.status !== 'Success') throw new Error('network');

            if (gravityLogin) {
                responseData = {
                    status: responseData.status,
                    data: responseData
                }
                delete responseData.communities;
                delete responseData.session;
                delete responseData.subscription;
                delete responseData.user;
            } else {
                setNameEntry(responseData.data.name);
                setEmailEntry(responseData.data.email);
                setUuid(responseData.data.uuid);
            }
            localStorage.setItem('gravityTempData', JSON.stringify({
                email: responseData.data.email,
                name: responseData.data.name,
                sessionId: responseData.data.sessionId,
                uuid: responseData.data.uuid
            }));

            setRestoring(false);
            setLoginTempRegister(false);
        }
    }

    const handlePlayPause = (play) => {
        setViewPrompt(play);
        if (play) {
            setTimeout(() => {
                if (videoVideo !== null) videoVideo.current.play();
            }, 250);
        } else {
            if (videoVideo !== null) videoVideo.current.pause();
        }
    }

    useEffect(() => {
        if (!fetching.current && !fetched.current) {
            fetching.current = true;
            document.querySelector('.recorderWrapper').style.display = 'none';
            document.querySelector('.buttonControls').style.display = 'none';

            setRestoring(true);

            try {
                const tempLogin = () => {
                    if (localStorage.getItem('gravityTempData') !== undefined && localStorage.getItem('gravityTempData') !== null) {
                        const localSession = JSON.parse(localStorage.getItem('gravityTempData'));
                        setNameEntry(localSession.name);
                        setEmailEntry(localSession.email);
                        setUuid(localSession.uuid);
                    } else {
                        setLoginTempRegister(true);
                    }
                    if (assetData !== null) setRestoring(false);
                }

                if (!restoreAttempt && params?.provider === 'g') {
                    setRestoreAttempt(true);

                    if (localStorage.getItem('gravitySession') !== undefined) {
                        const localSession = JSON.parse(localStorage.getItem('gravitySession'));
                        if (localSession !== null) {
                            restoreSession(localSession);
                        } else {
                            tempLogin();
                        }
                    } else {
                        tempLogin();
                    }
                } else {
                    tempLogin();
                }

                fetchRecorder();
            } catch (error) {
                fetching.current = false;
                fetched.current = true;
                setRestoring(false);
            }
        }
    }, [restoring, fetchRecorder, params?.spaceId]);

    return (
        <div className="PublicRecorder">
            <div className="publicOuter">
                <div className="publicWrapper">
                    <div className={`recorderBackground ${params?.provider}`} />
                    <Recorder
                        ref={recorderRef}
                        cameraStarted={cameraStarted}
                        uploading={uploading}
                        recordType={recordType}
                        setRecordType={(type) => recordType.current = type}
                        recorderSuccess={recorderSuccess}
                        setRecordedBlobs={(blobs) => setRecordedBlobs(blobs)}
                        setCameraStarted={(started) => setCameraStarted(started)}
                        setFollowUp={() => null}
                        setQuestionType={() => null}
                        submitMedia={(data) => submitMedia(data)}
                        mirrorMode={false}
                        sendMessage={() => null}
                        setFinalTranscript={(transcript) => setFinalTranscript(transcript)}
                        setFinalKeywords={(keywords) => setFinalKeywords(keywords)}
                        speaking={'en-US'}
                        setSpeaking={() => null}
                        uploader={uploader}
                        setUploader={(type) => setUploader(type)}
                        setUploaderType={(type) => setUploaderType(type)}
                        data={{
                            followUp: false,
                            questionType: null,
                            isSpace: false,
                            version: params?.provider,
                        }} />

                    {(!fetching.current && fetched.current && !embeddable) &&
                        <div className="notEmbeddable">
                            {strings.default[store.language].Embed.Sorry}
                        </div>
                    }

                    {(restoring || loginTempRegister) &&
                        <div className="recorderLogin">
                            <div className={`recorderType${gravityLogin ? ' gravityLogin' : ''}`}>
                                {(!gravityLogin && !restoring) &&
                                    <div className={`text ${params?.provider}`}>
                                        {`${strings.default[store.language].Embed.EnterDetails} ${strings.default[store.language].Embed[params?.provider === 'g' ? 'OrUse' : 'ToGetStarted']}`}
                                    </div>
                                }
                                {params?.provider === 'g' &&
                                    (gravityLogin ?
                                        <button
                                            className="guestLoginButton"
                                            onClick={() => setGravityLoginManager(!gravityLogin)}>
                                            <div className="green">
                                                {`<`}
                                            </div>
                                            <div className="regular">
                                                {strings.default[store.language].Embed.BackGuest}
                                            </div>
                                        </button> :
                                        !restoring &&
                                        <button
                                            className={`type ${params?.provider}`}
                                            onClick={() => setGravityLoginManager(!gravityLogin)}>
                                            <img
                                                className="gravityLogo"
                                                src={GravityLogoWhite}
                                                alt="Gravity Logo" />
                                            <div className="gravityText">
                                                {strings.default[store.language].LoginRegister.Login}
                                            </div>
                                        </button>)
                                }
                            </div>
                            {restoring ?
                                <div className="loginLoader">
                                    <Loading active={true} />
                                    <div className="loginText">
                                        {strings.default[store.language].LoginRegister.RestoringSession}
                                    </div>
                                </div> :
                                <>
                                    <div className={`recorderDetails ${params?.provider}`}>
                                        {!gravityLogin &&
                                            <div className="entryWrapper">
                                                <div className="entryLabel">
                                                    {strings.default[store.language].Embed.Name}
                                                </div>
                                                <div className="entryInputWrapper">
                                                    <input
                                                        className="entryInput"
                                                        type="text"
                                                        value={nameEntry}
                                                        onChange={(e) => {
                                                            setNameEntry(e.currentTarget.value);
                                                            checkValidEntry(e.currentTarget.value, 'name');
                                                        }} />
                                                </div>
                                            </div>
                                        }
                                        <div className="entryWrapper">
                                            <div className="entryLabel">
                                                {strings.default[store.language].Embed.Email}
                                            </div>
                                            <div className="entryInputWrapper">
                                                <input
                                                    className="entryInput"
                                                    type="email"
                                                    value={emailEntry}
                                                    onChange={(e) => {
                                                        setEmailEntry(e.currentTarget.value);
                                                        checkValidEntry(e.currentTarget.value, 'email');
                                                    }} />
                                            </div>
                                        </div>
                                        {gravityLogin &&
                                            <div className="entryWrapper">
                                                <div className="entryLabel">
                                                    {strings.default[store.language].LoginRegister.Password}
                                                </div>
                                                <div className="entryInputWrapper">
                                                    <input
                                                        className="entryInput"
                                                        type="password"
                                                        value={passwordEntry}
                                                        onChange={(e) => {
                                                            setPasswordEntry(e.currentTarget.value);
                                                            checkValidEntry(e.currentTarget.value, 'password');
                                                        }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className="loginSubmit">
                                        <button
                                            className={`submitButton login ${params?.provider}`}
                                            onClick={() => submitDetails()}
                                            disabled={!submitValid}>
                                            {gravityLogin ? strings.default[store.language].LoginRegister.Login : strings.default[store.language].Embed.StartRecording}
                                        </button>
                                        {gravityLogin &&
                                            <GoogleOAuthProvider clientId={constants.strings.googleClientID}>
                                                <Google
                                                    source="Login"
                                                    loginSuccess={(responseData) => submitSocial(responseData)} />
                                            </GoogleOAuthProvider>
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    }
                    {(assetData !== null && !loginTempRegister) &&
                        <div className={`promptViewer${hide ? ' hidden' : ''}`}>
                            <div
                                className="videoWrapper"
                                style={{ backgroundImage: `url("${constants.services.awsLocation(true)}/${assetData.aid}.gif")` }}>
                                <button
                                    className="overlayPlayButton"
                                    disabled={!(assetData.metadata.location.length && assetData.metadata.location !== null)}
                                    onClick={() => handlePlayPause(true)}>
                                    <div className="openImage">
                                        <img
                                            className="openIcon"
                                            src={openIcon}
                                            alt="Open video" />
                                    </div>
                                </button>
                            </div>
                            <div className="videoData">
                                <div className="videoTitle">
                                    {constants.utils.truncateString(assetData.metadata.title, 46)}
                                </div>
                                <div className="videoDescription">
                                    <div className="description">
                                        {assetData.metadata.description}
                                    </div>
                                </div>
                            </div>
                            <button
                                className="hide"
                                onClick={() => setHide(!hide)}>
                                {strings.default[store.language].Modal[hide ? 'Show' : 'Hide']}
                            </button>
                        </div>
                    }

                    <div className={`logoWrapper ${params?.provider}`}>
                        {renderLogo(params?.provider)}
                    </div>
                    {(embeddable && !uploadComplete) &&
                        <button
                            className={`submitButton recorder ${params?.provider}`}
                            onClick={() => submitMedia()}
                            disabled={!recordedBlobs.length || uploading}>
                            {strings.default[store.language].Modal.Submit}
                        </button>
                    }
                    <div className="submitLoader">
                        <Loading active={uploading} />
                    </div>
                    {uploadComplete &&
                        <div className={`successWrapper ${params?.provider}`}>
                            <div className="confettiBack" />
                            <img
                                className={`logoShow ${params?.provider}`}
                                src={params?.provider === 'g' ? GravityTriangle : LitworldBluebird}
                                alt={params?.provider === 'g' ? 'Gravity Logo' : 'LitWorld Bluebird'} />
                            <div className="successText">
                                <div className={`top ${params?.provider}`}>
                                    {strings.default[store.language].Embed.Success}
                                </div>
                                <div className={`text ${params?.provider}`}>
                                    {strings.default[store.language].Embed[params?.provider === 'g' ? 'VisitGravity' : 'StayTuned']}
                                </div>
                            </div>
                        </div>
                    }
                    {(assetData !== null && viewPrompt) &&
                        <div className="videoWrapperFull">
                            <video
                                className="videoVideo"
                                ref={videoVideo}
                                src={assetData.metadata.location}
                                alt={assetData.metadata.title}
                                playsInline
                                controls />
                            <button
                                className="closeVideo"
                                onClick={() => handlePlayPause(false)}>
                                {strings.default[store.language].Modal.Close}
                            </button>
                        </div>
                    }
                </div>
            </div>
        </div >
    );
}

export default PublicRecorder;