import React from 'react';
import * as constants from './components/exports/constants';

export const state = {
  language: 'en',
  profile: {},
  subscription: {
    active: false,
    type: null,
    tokens: null
  },
  communities: {
    data: [],
    fetched: false
  },
  groups: {
    data: [],
    fetched: false
  },
  spaces: {
    data: [],
    fetched: false
  },
  assets: {
    data: {},
    fetched: false
  },
  status: {
    community: {},
    group: {},
    space: {},
    asset: {},
    members: [],
    message: {
      type: null,
      text: null
    },
    modal: {
      active: false,
      action: null,
      type: null,
      store: null,
      thread: null,
      data: {
        title: null,
        description: null,
        inputTitle: null,
        placeholder: null,
        privacySetting: false
      }
    },
    recorder: {
      active: false,
      responding: {
        active: false,
        recording: false,
        started: false
      }
    },
    settings: {
      transcript: false,
      translate: false,
      public: false
    },
    menu: true,
    tutorials: {
      active: false,
      backFrom: false,
      content: null
    }
  },
  session: {},
  deeplink: {
    params: {},
    parsed: false,
    loaded: {
      communities: false,
      groups: false,
      spaces: false,
      assets: false,
    },
    premium: false
  },
  reload: {
    header: null,
    communities: null
  },
  marketing: {
    school: null
  },
  viewer: {
    active: false
  }
};

export const reducer = (state, action) => {
  switch (action.type) {
    case "store": 
      if (constants.services.devMode) console.log(action.data)
      return action.data
    default: 
      if (constants.services.devMode) console.log({...state, [action.type]: action.data})
      return {
        ...state,
        [action.type]: action.data
      }
  }
};

export const Context = React.createContext();
