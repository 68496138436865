import { useContext } from 'react';
import { Context } from '../../DataStore';
import { useHistory } from "react-router-dom";

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/dashboard/QuickMenu.scss';

const ProfileShortcut = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/profile-shortcut.svg`;
const EmailShortcut = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/email.svg`;
const LogoutShortcut = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/logout-shortcut.svg`;
const TutorialsShortcut = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/tutorials.svg`;

const QuickMenu = () => {
  const { store, dispatch } = useContext(Context);
  const history = useHistory();

  const openTutorials = () => {
    const statusCopy = {
      ...store.status,
      tutorials: {
        active: true,
        backFrom: true,
        content: null
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
    history.push('/t/');
  }

  const openProfile = () => {
    const statusCopy = {
      ...store.status,
      modal: {
        active: true,
        action: 'editProfile',
        data: {
          title: strings.default[store.language].Header.EditProfile,
          description: strings.default[store.language].Modal.ChangeDescription,
          inputTitle: strings.default[store.language].LoginRegister.FullName,
          value: store.profile.name,
          placeholder: strings.default[store.language].AssetsView.ResponseDescriptionPlaceholder,
          privacySetting: false
        }
      }
    }
    dispatch({
      type: 'status',
      data: statusCopy
    });
  }

  const openEmail = () => {
    window.location.href = 'mailto:hello@usegravity.io';
  }

  const logout = () => {
    const languageArray = window.navigator.language.split('');
    const detectedLanguage = `${languageArray[0]}${languageArray[1]}`;
    const languages = ['en', 'zh', 'nl'];

    const storeCopy = {
      language: ((languageArray.length > 1 ? (languages.includes(detectedLanguage) ? detectedLanguage : 'en') : 'en')),
      profile: {},
      subscription: {
        active: false,
        type: null,
        tokens: null
      },
      communities: {
        data: [],
        fetched: false
      },
      groups: {
        data: [],
        fetched: false
      },
      spaces: {
        data: [],
        fetched: false
      },
      assets: {
        data: {},
        fetched: false
      },
      status: {
        community: {},
        group: {},
        space: {},
        asset: {},
        members: [],
        message: {
          type: null,
          text: null
        },
        modal: {
          active: false,
          action: null,
          type: null,
          store: null,
          thread: null,
          data: {
            title: null,
            description: null,
            inputTitle: null,
            placeholder: null,
            privacySetting: false
          }
        },
        recorder: {
          active: false,
          responding: {
            active: false,
            recording: false,
            started: false
          }
        },
        settings: {
          transcript: false,
          translate: false,
          public: false,
        },
        menu: true,
        tutorials: {
          active: false,
          backFrom: false,
          content: null
        }
      },
      session: {}
    };
    dispatch({
      type: 'store',
      data: storeCopy,
    });
    constants.services.endSession();
    history.push("/login/");
  }

  const buttons = [
    /*{
      func: openMirror,
      src: MirrorShortcut,
      text: strings.default[store.language].Dashboard.CheckMirror
    },*/
    {
      func: openTutorials,
      src: TutorialsShortcut,
      text: strings.default[store.language].Dashboard.Tutorials
    },
    {
      func: openProfile,
      src: ProfileShortcut,
      text: strings.default[store.language].Header.EditProfile
    },
    {
      func: openEmail,
      src: EmailShortcut,
      text: strings.default[store.language].Modal.ContactUs
    }, {
      func: logout,
      src: LogoutShortcut,
      text: strings.default[store.language].Header.Logout
    }
  ]

  return (
    <div className="QuickMenu dashboardModule">
      <div className="moduleWrapper">
        <div className="moduleName">
          {strings.default[store.language].Dashboard.QuickMenu}
        </div>
        <div className="moduleContent">
          <div className="moduleBox">
            {buttons.map((button, i) => (
              <div
                className="shortcutWrapper"
                key={`Button${i}`}>
                <div className="shortcutButtonWrapper">
                  <button
                    className={`shortcutButton${button.text === strings.default[store.language].Dashboard.Tutorials ? ' tutorials' : ''}`}
                    onClick={button.func}>
                    <img
                      className="shortcutImage"
                      src={button.src}
                      alt={button.text} />
                    <div className="shortcutText">
                      {button.text}
                    </div>
                    <div className="shortcutLine" />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuickMenu;
