import { useContext, useState, useRef, useEffect } from 'react';
import { Context } from '../../DataStore';
import { useParams } from 'react-router-dom';

import Video from "../common/Video";

import * as strings from '../../data/strings';
import * as constants from '../exports/constants';

import '../../styles/embed/Widget.scss';

const GravityLogo = `${process.env.REACT_APP_CF_APP_ENDPOINT}svg/gravity-logo.svg`;

const Widget = () => {
    const { store } = useContext(Context);
    const params = useParams();

    const [assetData, setAssetData] = useState(null);
    //const [views, setViews] = useState([]);

    const fetching = useRef(false);
    const fetched = useRef(false);

    const processView = async (e, aid, creator) => {
        // TO-DO: new metrics for embed widgets
        /*if (!views.includes(aid)) {
            setViews([
                ...views,
                aid
            ]);
            let data = {
                uuid: store.profile.uuid,
                sessionId: store.session.sessionId,
                aid: aid,
                creator: creator
            }

            const url = `${constants.services.url.api}/view/create/`;
            const response = await fetch(url, constants.services.config(data));
            const responseData = await response.json();

            if (response.ok && responseData.status !== 'Success') {
                const index = views.indexOf(aid);
                if (index > -1) {
                    const newViews = views.splice(index, 1);
                    setViews(newViews);
                }
            }
        }*/
    }

    useEffect(() => {
        if (!fetching.current && !fetched.current) {
            fetching.current = true;

            try {
                (async () => {
                    const response = await constants.services.configParams('/asset/fetch/embed/', { aid: params?.assetId });
                    const responseData = await response.json();
                    
                    fetching.current = false;
                    fetched.current = true;

                    if (responseData.status === 'Success') setAssetData(responseData.data);
                })();
            } catch (error) {
                fetching.current = false;
                fetched.current = true;
            }
        }
    }, [params?.assetId]);

    return (
        <div className="Widget">
            <div className="widgetOuter">
                <div className="widgetWrapper">
                    <div className="widgetBackground" />
                    <div className="widgetHeader">
                        <div className="widgetHeaderInner">
                            <button className="gravityHome">
                                <img
                                    className="gravityLogo"
                                    src={GravityLogo}
                                    alt="Gravity logo" />
                            </button>
                            <div className="gravityPower">
                                {strings.default[store.language].Embed.PoweredBy} <a
                                    className="visitAnchor"
                                    href="https://usegravity.io/"
                                    target="_blank"
                                    rel="noreferrer">
                                    usegravity.io
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="widgetVideo">
                        <Video
                            setSrc={constants.services.s3ToCloudfrontMedia(assetData?.asset?.location)}
                            setPoster={`${assetData?.asset?.aid}.gif`}
                            onPlay={(e) => processView(e, assetData?.asset?.aid, assetData?.asset?.uuid)}
                            transcript=""
                            width="520"
                            type="video"
                            level="main"
                            settings={{
                                transcript: false,
                                translate: false
                            }} />
                    </div>
                    <div className="widgetData">
                        {assetData &&
                            <>
                                <div className="responderWrapper">
                                    <div className="responder">
                                        <div
                                            className="responderAvatar"
                                            style={{ backgroundImage: `url(${constants.services.s3ToCloudfrontMedia(assetData?.creator?.image)})` }} />
                                    </div>
                                    <div className="metadata">
                                        <div className="name">
                                            {assetData?.creator?.name}
                                        </div>
                                        <div className="creation">
                                            {constants.utils.parseDateTime(assetData?.creation)}
                                        </div>
                                    </div>
                                </div>
                                <div className="descriptionWrapper">
                                    <div className="triangleUp" />
                                    <div className="description">
                                        <div className="descriptionText">
                                            {assetData?.asset?.description}
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        <div className={`visit${assetData ? '' : ' empty'}`}>
                            <div className="gravityJoinWrapper">
                                <a 
                                    className="gravityJoin"
                                    href={`https://usegravity.io/g/${assetData?.asset?.cid}/${assetData?.asset?.gid}/${assetData?.asset?.sid}/`} 
                                    target="_blank"
                                    rel="noreferrer">
                                    {strings.default[store.language].Embed.JoinConversation}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Widget;