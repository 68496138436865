import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';

import Footer from '../common/Footer';

import Marketing from '../marketing/Marketing';
import Login from './Login';
import Register from './Register';
import Widget from '../embed/Widget';
import PublicRecorder from '../embed/PublicRecorder';
import Dashboard from './Dashboard';
import Privacy from '../content/Privacy';
import TermsOfService from '../content/TermsOfService';
import Viewer from '../embed/Viewer';

import * as constants from '../exports/constants';

import '../../styles/composite/Application.scss';
import '../../styles/exports/animation.scss';
import VideoCarousel from '../embed/VideoCarousel';

const ApplicationContent = () => {
  const location = useLocation();
  const isEmbed = location.pathname.includes('/e/');
  const isRecorder = location.pathname.includes('/e/recorder/');
  const isCarousel = location.pathname.includes('/e/carousel/');
  const isViewer = location.pathname.includes('/e/viewer/');

  return (
    <div
      className={`Application${
        constants.utils.isMobile() ? ' mobile' : ''
      }${isEmbed ? ` ${isRecorder ? 'recorder' : isCarousel ? 'carousel' : isViewer ? 'viewer' : 'embed'}Module` : ''}`}>
      <div className={`mainContent${
        isEmbed ? ` ${isRecorder ? 'recorder' :  isCarousel ? 'carousel' : isViewer ? 'viewer' : 'embed'}Module` : ''}`}>
        {/* Customized school welcome links */}
        <Route path={["/", "/welcome/:school"]} component={Marketing} exact />

        {/* Login and Register components */}
        <Route path={["/login/"]} component={Login} exact />
        <Route path={["/register/"]} component={Register} exact />

        {/* Embeddable iFrames */}
        <Route path={["/e/player/:provider/:assetId"]} component={Widget} exact />
        <Route path={["/e/recorder/:provider/:spaceId"]} component={PublicRecorder} exact />
        <Route path={["/e/carousel/:provider/:spaceId"]} component={VideoCarousel} exact />
        <Route path={["/e/viewer/:provider/:spaceId"]} component={Viewer} exact />

        {/* Application routing */}
        <Route
          path={[
            "/dashboard/",
            "/dashboard/premium/",
            "/t/",
            "/t/:tutorialId",
            "/g/",
            "/g/:communityId",
            "/g/:communityId/:groupId",
            "/g/:communityId/:groupId/:spaceId",
            "/g/:communityId/:groupId/:spaceId/:assetId",
          ]}
          component={Dashboard}
          exact
        />

        {/* Privacy and TOS links */}
        <Route path={["/privacy/"]} component={Privacy} exact />
        <Route path={["/tos/"]} component={TermsOfService} exact />
      </div>
      <Footer />
    </div>
  );
};

const Application = () => {
  return (
    <Router>
      <ApplicationContent />
    </Router>
  );
};

export default Application;
