import { useState } from "react";

import '../../styles/embed/VideoCarousel.scss';

const LitworldFox = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/litworld/fox14.png`;
const LitworldElephant = `${process.env.REACT_APP_CF_APP_ENDPOINT}png/litworld/Elephant.png`;

const VideoCarousel = () => {
    const videoUrls = [
        "1_BAS.mov",
        "2_BAS.mp4",
        "3_BAS.mp4",
        "4_BAS.mov",
        "5_BAS.mp4",
    ];
    const [currentIndex, setCurrentIndex] = useState(0);

    const handlePrev = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === 0 ? videoUrls.length - 1 : prevIndex - 1
        );
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) =>
            prevIndex === videoUrls.length - 1 ? 0 : prevIndex + 1
        );
    };

    return (
        <div className="VideoCarousel">
            <div className="carouselOuter">
                <div className="carouselWrapper">
                    <button
                        onClick={() => handlePrev()}
                        className="navButton left">
                        <div className="navIcon left">
                            ◀
                        </div>
                    </button>
                    <div className="videoWrapper">
                        <video
                            src={`https://dmn09ay7q1luk.cloudfront.net/${videoUrls[currentIndex]}`}
                            controls
                            className="videoPlayer"
                        />
                    </div>
                    <button
                        onClick={() => handleNext()}
                        className="navButton right">
                        <div className="navIcon right">
                            ▶
                        </div>
                    </button>
                    <div className="decoration fox">
                        <img
                            className="fox"
                            src={LitworldFox}
                            alt="Litworld Fox" />
                    </div>
                    <div className="decoration elephant">
                        <img
                            className="elephant"
                            src={LitworldElephant}
                            alt="Litworld Elephant" />
                    </div>
                </div>
                <div className="carouselCount">
                    {currentIndex + 1} <span className="of">of</span> {videoUrls.length}
                </div>
            </div>
        </div>
    );
};

export default VideoCarousel;